// combine the two interfaces
import { usePlayerShared } from '@grandstand-web/player/src/context/PlayerSharedContext'
import { TPlayerProps } from '../types'
import { PlayerUpcoming } from './PlayerUpcoming'
import VideoErrorView from './VideoErrorView'
import { VideoLoading } from './VideoLoading'
import VideoLoadingOrBuffering from './VideoLoadingOrBuffering'
import VideoPlayerWrapper from './VideoPlayerWrapper'
import VideoTitle from './VideoTitle'

type PlayerComponent = React.FC<TPlayerProps> & {
  LoadingOrBuffering: typeof VideoLoadingOrBuffering
  Wrapper: typeof VideoPlayerWrapper
  Loading: typeof VideoLoading
  ErrorView: typeof VideoErrorView
  Title: typeof VideoTitle
}

const Player: PlayerComponent = (props: TPlayerProps) => {
  const { isShaka, playerType } = usePlayerShared()

  let isUpcoming = false

  if (props.start) {
    const now = new Date().getTime()
    const start = new Date(props.start).getTime()
    isUpcoming = start > now
  }

  if (isUpcoming) {
    return <PlayerUpcoming image={props.image} />
  }

  const Player = isShaka ? require('./PlayerShaka').default : require('./PlayerBitmovin').default
  return <Player {...props} playerType={playerType} />
}
Player.LoadingOrBuffering = VideoLoadingOrBuffering
Player.Wrapper = VideoPlayerWrapper
Player.Loading = VideoLoading
Player.ErrorView = VideoErrorView
Player.Title = VideoTitle

export { Player as default, Player }
